import * as actionTypes from '../actions';

const initialState = {
    showAlert: false,
    alertMessage: ""
}

const reducer = (state = initialState, action) => {
    if (action.type === actionTypes.CHANGEALERTSTATUS) {
        return {
            showAlert: action.showAlert,
            alertMessage: action.alertMessage
        }
    }
    return state;
}



export default reducer;