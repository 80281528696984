import { reactLocalStorage } from 'reactjs-localstorage';
import constantData from './appConstant';

export const getAuthToken = async () => {
    const authData = reactLocalStorage.getObject('authData');
    const expiryAt = authData.expiry_at;
    const now = new Date();
    if (now.getTime() > expiryAt) {
        var details = {
            grant_type: "refresh_token",
            client_id: "self",
            refresh_token: authData.refresh_token,
        };
        var formBody = [];
        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        const result = await getRefreshToken(formBody).then(response => response.json()).then(result => {
            if (typeof result['expires_in'] !== 'undefined') {
                const authData = reactLocalStorage.getObject('authData');
                const now = new Date();
                now.setSeconds(now.getSeconds() + result['expires_in']);
                authData.expiry_at = now.getTime();
                authData.access_token = result['access_token'];
                authData.refresh_token = result['refresh_token'];
                reactLocalStorage.setObject('authData', authData);
                return "Bearer " + result['access_token'];
            } else {
                return null;
            }
        }).catch(function (error) {
            return null;
        });

        if (result === null) {
            reactLocalStorage.remove('authData');
            reactLocalStorage.remove('authUserData');
            reactLocalStorage.clear();
        }
        return result;
    } else {
        return "Bearer " + authData.access_token;
    }
}

export const getOnlyAuthToken = () => {
    const authData = reactLocalStorage.getObject('authData');
    return "Bearer " + authData.access_token;
}

const getRefreshToken = async (formBody) => {
    const result = fetch(constantData.baseUrl + "token", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: formBody
    });
    return result;
}