import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from './containers/login/login.js';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { loadProgressBar } from 'axios-progress-bar'
import 'axios-progress-bar/dist/nprogress.css';
import ForgetPassword from './containers/forgetPassword/forgetPassword.js';
import FirstTimeLogin from './containers/firstTimeLogin/firstTimeLogin.js';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#003966",
    },
    secondary: {
      main: "#d4101a",
    },
    background: {
      default: "#eee",
    }
  },
  typography: {
    fontFamily: [
      'Lato',
      'sans-serif',
    ].join(','),
  },
});


function App() {

  const AfterLogin = React.lazy(() => import('./hoc/appLayout/appLayout.js'));

  return (
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <React.Fragment>
          {loadProgressBar()}
          <Switch>
            <Route
              path="/app"
              render={() => (
                <Suspense fallback={<div>Loading...</div>}>
                  <AfterLogin />
                </Suspense>
              )}
            />
            <Route path="/forget-password" component={ForgetPassword} exact />
            <Route path="/firstime-login" component={FirstTimeLogin} exact />
            <Route path="/" component={Login} exact />
            <Route component={Login} />
          </Switch>
        </React.Fragment>
      </BrowserRouter>
    </MuiThemeProvider>
  );
}

export default App;
