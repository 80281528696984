import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import checkValidity from '../../common/appValidation';
import constantData from '../../common/appConstant.js';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';
import axios from 'axios';
import { getOnlyAuthToken } from '../../common/appAuthTokenProvider';
import AppAlert from '../../common/appAlert';
import FormInputs from '../../components/UI/formInputs/formInputs.js';
import Grid from '@material-ui/core/Grid';
import { reactLocalStorage } from 'reactjs-localstorage';

const useStyles = makeStyles((theme) => ({
    firstTimeLoginContainer: {
        minWidth: 275,
    }
}
));

function FirstTimeLogin(props) {

    useEffect(() => {
        props.onApiCall();
        props.onAlertAction(false, "");
        axios.get(constantData.baseUrl + 'api/GetMasterList?Mastercode=SecretQues').then(function (response) {
            props.onApiCall();
            if (response.status == "200") {
                createSecreatQuestion(response.data);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }, []);

    const classes = useStyles();
    const [questionSelect, setQuestionSelect] = useState([]);
    const [firstTimeLoginFormState, setfirstTimeLoginFormState] = useState({
        firstTimeLoginFormUserDetail: {
            oldPassword: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Old Password'
                },
                value: '',
                validation: {
                    required: true,
                },
                validationMessage: {
                    required: "Old Password is required",
                },
                selectOption: [],
                errorMessage: "",
                valid: false,
                touched: false
            },
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'New Password'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 7,
                    password: true,
                    maxLength: 30
                },
                validationMessage: {
                    required: "Password is required",
                    minLength: "Minimum 7 characters in length",
                    others: "Please enter valid data",
                    password: "Password should have at least, 1 Capital Letter, 1 Number, 1 Special Character",
                    maxLength: "Please enter valid data"
                },
                selectOption: [],
                errorMessage: "",
                valid: false,
                touched: false
            },
            confirmPassword: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Confirm Password'
                },
                value: '',
                validation: {
                    required: true,
                    sameAs: "password",
                },
                validationMessage: {
                    required: "Confirm Password is required",
                    sameAs: "Confirm Password should be same as New Password",
                    others: "Please enter valid data",
                },
                selectOption: [],
                errorMessage: "",
                valid: false,
                touched: false
            },
        },
        firstTimeLoginForm: {
            secretQuestionOne: {
                elementType: 'select-single',
                elementConfig: {
                    type: 'select',
                    placeholder: 'Secret Question One'
                },
                value: '',
                validation: {
                    required: true,
                },
                validationMessage: {
                    required: "Secret Question One is required",
                },
                selectOption: [],
                errorMessage: "",
                valid: false,
                touched: false
            },
            secretAnswerOne: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Secret Answer One'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 3,
                    maxLength: 30
                },
                validationMessage: {
                    required: "Secret Answer One is required",
                    minLength: "Please enter valid data",
                    maxLength: "Please enter valid data"
                },
                selectOption: [],
                errorMessage: "",
                valid: false,
                touched: false
            },
            secretQuestionTwo: {
                elementType: 'select-single',
                elementConfig: {
                    type: 'select',
                    placeholder: 'Secret Question Two'
                },
                value: '',
                validation: {
                    required: true,
                },
                validationMessage: {
                    required: "Secret Question Two is required",
                },
                selectOption: [],
                errorMessage: "",
                valid: false,
                touched: false
            },
            secretAnswerTwo: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Secret Two Answer'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 3,
                    maxLength: 30
                },
                validationMessage: {
                    required: "Secret Two Answer is required",
                    minLength: "Please enter valid data",
                    maxLength: "Please enter valid data"
                },
                selectOption: [],
                errorMessage: "",
                valid: false,
                touched: false
            },
        },
        formIsValid: false,
        loading: false
    });

    const formElementsArray = [];
    for (let key in firstTimeLoginFormState.firstTimeLoginForm) {
        formElementsArray.push({
            id: key,
            config: firstTimeLoginFormState.firstTimeLoginForm[key]
        });
    }

    const formElementsArrayPassword = [];
    for (let key in firstTimeLoginFormState.firstTimeLoginFormUserDetail) {
        formElementsArrayPassword.push({
            id: key,
            config: firstTimeLoginFormState.firstTimeLoginFormUserDetail[key]
        });
    }

    const createSecreatQuestion = (data) => {
        const updatedOrderForm = {
            ...firstTimeLoginFormState.firstTimeLoginForm
        };
        const updatedOrderFormPassword = {
            ...firstTimeLoginFormState.firstTimeLoginFormUserDetail
        };
        const createNewSelectArray = [];
        for (let key in data) {
            createNewSelectArray.push({
                id: data[key].mstindex.trim(),
                value: data[key].msttext
            });
        }
        setQuestionSelect(createNewSelectArray);
        updatedOrderForm['secretQuestionOne'].selectOption = createNewSelectArray;
        updatedOrderForm['secretQuestionTwo'].selectOption = createNewSelectArray;
        setfirstTimeLoginFormState({ firstTimeLoginFormUserDetail: updatedOrderFormPassword, firstTimeLoginForm: updatedOrderForm, formIsValid: false, loading: false });
    }

    let history = useHistory();


    const firstTimeLoginHandler = (event) => {
        event.preventDefault();
        if (checkIsFormValid()) {
            const formData = {};
            for (let formElementIdentifier in firstTimeLoginFormState.firstTimeLoginForm) {
                formData[formElementIdentifier] = firstTimeLoginFormState.firstTimeLoginForm[formElementIdentifier].value;
            }

            const authUserData = reactLocalStorage.getObject('authUserData');
            var details = {
                wrkuserid: authUserData['wrkuserid'],
                oldPassword: btoa(firstTimeLoginFormState.firstTimeLoginFormUserDetail["oldPassword"].value),
                password: btoa(firstTimeLoginFormState.firstTimeLoginFormUserDetail["password"].value),
                secretquestion1: formData["secretQuestionOne"],
                secretquestion2: formData["secretQuestionTwo"],
                secretanswer1: btoa(formData["secretAnswerOne"]),
                secretanswer2: btoa(formData["secretAnswerTwo"]),
            };

            var formBodyLoginStatus = [];
            for (var property in details) {
                var encodedKey = encodeURIComponent(property);
                var encodedValue = encodeURIComponent(details[property]);
                formBodyLoginStatus.push(encodedKey + "=" + encodedValue);
            }
            formBodyLoginStatus = formBodyLoginStatus.join("&");

            fetch(constantData.baseUrl + 'api/UpdateUserAuthentication?' + formBodyLoginStatus, {
                method: 'POST',
                headers: {
                    'Authorization': getOnlyAuthToken()
                }
            }).then(response => response.json()).then(result => {
                //props.onApiCall();
                if (result !== 'SUCCESS') {
                    props.onAlertAction(true, result);
                } else {
                    history.push("/");
                    props.onAlertAction(true, "Success!!! Password updated successfully");
                }
            });


        }
    };

    const checkIsFormValid = () => {
        const updatedOrderForm = {
            ...firstTimeLoginFormState.firstTimeLoginForm
        };
        const updatedOrderFormPassword = {
            ...firstTimeLoginFormState.firstTimeLoginFormUserDetail
        };
        let formIsValid = true;
        for (let formElementIdentifier in firstTimeLoginFormState.firstTimeLoginForm) {
            const validationResult = checkValidity(updatedOrderForm[formElementIdentifier].value, updatedOrderForm[formElementIdentifier].validation, updatedOrderForm[formElementIdentifier].validationMessage);
            updatedOrderForm[formElementIdentifier].valid = validationResult.validAction;
            updatedOrderForm[formElementIdentifier].errorMessage = validationResult.errorMessage;
            updatedOrderForm[formElementIdentifier].touched = true;
            formIsValid = !updatedOrderForm[formElementIdentifier].valid && formIsValid;
        }
        for (let formElementIdentifier in firstTimeLoginFormState.firstTimeLoginFormUserDetail) {
            const validationResult = checkValidity(updatedOrderFormPassword[formElementIdentifier].value, updatedOrderFormPassword[formElementIdentifier].validation, updatedOrderFormPassword[formElementIdentifier].validationMessage, updatedOrderFormPassword);
            updatedOrderFormPassword[formElementIdentifier].valid = validationResult.validAction;
            updatedOrderFormPassword[formElementIdentifier].errorMessage = validationResult.errorMessage;
            updatedOrderFormPassword[formElementIdentifier].touched = true;
            formIsValid = !updatedOrderFormPassword[formElementIdentifier].valid && formIsValid;
        }
        setfirstTimeLoginFormState({ firstTimeLoginFormUserDetail: updatedOrderFormPassword, firstTimeLoginForm: updatedOrderForm, formIsValid: formIsValid });
        return formIsValid;
    }


    const inputChangedHandler = (event, inputIdentifier) => {
        const updatedOrderForm = {
            ...firstTimeLoginFormState.firstTimeLoginForm
        };
        const updatedOrderFormPassword = {
            ...firstTimeLoginFormState.firstTimeLoginFormUserDetail
        };
        let formIsValid = false;
        if (typeof updatedOrderForm[inputIdentifier] != 'undefined') {
            const updatedFormElement = {
                ...updatedOrderForm[inputIdentifier]
            };
            updatedFormElement.value = event.target.value;
            const validationResult = checkValidity(updatedFormElement.value, updatedFormElement.validation, updatedFormElement.validationMessage);
            updatedFormElement.valid = validationResult.validAction;
            updatedFormElement.errorMessage = validationResult.errorMessage;
            updatedFormElement.touched = true;
            updatedOrderForm[inputIdentifier] = updatedFormElement;
            if (inputIdentifier == "secretQuestionOne" && updatedFormElement.value != "") {
                const getListdata = [
                    ...questionSelect.filter(questionSelect => (questionSelect.id != updatedFormElement.value) ?? questionSelect)
                ];
                updatedOrderForm["secretQuestionTwo"].selectOption = getListdata;
            }
            if (inputIdentifier == "secretQuestionTwo" && updatedFormElement.value != "") {
                const getListdata = [
                    ...questionSelect.filter(questionSelect => (questionSelect.id != updatedFormElement.value) ?? questionSelect)
                ];
                updatedOrderForm["secretQuestionOne"].selectOption = getListdata;
            }
            for (let inputIdentifier in updatedOrderForm) {
                formIsValid = updatedOrderForm[inputIdentifier].valid && formIsValid;
            }
        }

        if (typeof updatedOrderFormPassword[inputIdentifier] != 'undefined') {
            const updatedFormElement = {
                ...updatedOrderFormPassword[inputIdentifier]
            };
            updatedFormElement.value = event.target.value;
            const validationResult = checkValidity(updatedFormElement.value, updatedFormElement.validation, updatedFormElement.validationMessage, updatedOrderFormPassword);
            updatedFormElement.valid = validationResult.validAction;
            updatedFormElement.errorMessage = validationResult.errorMessage;
            updatedFormElement.touched = true;
            updatedOrderFormPassword[inputIdentifier] = updatedFormElement;

            for (let inputIdentifier in updatedOrderFormPassword) {
                formIsValid = updatedOrderFormPassword[inputIdentifier].valid && formIsValid;
            }
        }
        setfirstTimeLoginFormState({ firstTimeLoginFormUserDetail: updatedOrderFormPassword, firstTimeLoginForm: updatedOrderForm, formIsValid: formIsValid });
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="#1A2960"
            height="100vh"
        >
            <AppAlert />
            <Container maxWidth="md">
                <Card className={classes.firstTimeLoginContainer}>
                    <CardContent>
                        <div align='center' spacing={2}>
                            <h4>Setup your New Password and your Secret answers</h4>
                        </div>
                        <form noValidate autoComplete="off" onSubmit={firstTimeLoginHandler}>
                            <Grid container spacing={2} direction="row"
                                justify="center"
                                alignItems="start">
                                {formElementsArrayPassword.map(formElement => (
                                    <Grid item xs={12} sm={4} key={formElement.id}>
                                        <FormInputs
                                            itemRequired={formElement.config.validation.required}
                                            itemId={formElement.id}
                                            changeHandaler={(event) => inputChangedHandler(event, formElement.id)}
                                            itemPlaceholder={formElement.config.elementConfig.placeholder}
                                            itemValid={formElement.config.valid}
                                            itemType={formElement.config.elementConfig.type}
                                            errorMessage={formElement.config.errorMessage}
                                            elementType={formElement.config.elementType}
                                            selectOptions={formElement.config.selectOption}
                                            inputData={formElement.config.value}
                                        />
                                    </Grid>
                                ))}
                                {formElementsArray.map(formElement => (
                                    <Grid item xs={12} sm={6}>
                                        <FormInputs key={formElement.id}
                                            itemRequired={formElement.config.validation.required}
                                            itemId={formElement.id}
                                            changeHandaler={(event) => inputChangedHandler(event, formElement.id)}
                                            itemPlaceholder={formElement.config.elementConfig.placeholder}
                                            itemValid={formElement.config.valid}
                                            itemType={formElement.config.elementConfig.type}
                                            errorMessage={formElement.config.errorMessage}
                                            elementType={formElement.config.elementType}
                                            selectOptions={formElement.config.selectOption}
                                            inputData={formElement.config.value}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            <div align="center">
                                <Button variant="contained" disabled={props.lodScr} type="submit" color="secondary" style={{ marginTop: 20 }}>
                                    Submit
                                </Button>
                            </div>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        </Box>
    );
}

const mapStateToProps = state => {
    return {
        lodScr: state.lodingRed.showLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onApiCall: () => dispatch({ type: actionTypes.SHOWLOADING }),
        onAlertAction: (showAlert, alertMessage) => dispatch({ type: actionTypes.CHANGEALERTSTATUS, alertMessage: alertMessage, showAlert: showAlert })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstTimeLogin);

