import React from 'react'
import classes from './formValidationMessage.module.css';

const FormValidation = props => {
    if (props.errorMessage !== "") {
        return (
            <div className={classes.ValidationError}>
                {props.errorMessage}
            </div>
        )
    } else {
        return (
            <div>

            </div>
        )
    }
}

export default FormValidation
