const checkValidity = (value, rules, validationMessage = {}, otherData = {}) => {

    let isValid = true;
    let message = "";
    if (!rules) {
        return true;
    }

    if (rules.required) {
        if (typeof value == "string") {
            isValid = value.trim() !== '' && isValid;
        } else if (typeof value == "array" || typeof value == "object") {
            isValid = value.length > 0 && isValid;
        } else {
            isValid = value !== '' && isValid;
        }
        if (!isValid && typeof validationMessage.required != undefined && !message) {
            message = validationMessage.required;
        }
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid;
        if (!isValid && typeof validationMessage.minLength != undefined && !message) {
            message = validationMessage.minLength;
        }
    }

    if (rules.sameAs) {
        console.log(otherData[rules.sameAs].value);
        isValid = value === otherData[rules.sameAs].value && isValid;
        if (!isValid && typeof validationMessage.sameAs != undefined && !message) {
            message = validationMessage.sameAs;
        }
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid;
        if (!isValid && typeof validationMessage.maxLength != undefined && !message) {
            message = validationMessage.maxLength;
        }
    }

    if (rules.isEmail && value !== "") {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid;
        console.log(value);
        if (!isValid && typeof validationMessage.email != undefined && !message) {
            message = validationMessage.email;
        }
    }

    if (rules.password) {
        const pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{7,})");
        isValid = pattern.test(value) && isValid;
        if (!isValid && typeof validationMessage.password != undefined && !message) {
            message = validationMessage.password;
        }
    }

    if (rules.dateValidation) {
        var myDate = new Date(value);
        var today = new Date();
        isValid = myDate < today && isValid;
        if (!isValid && typeof validationMessage.dateValidation != undefined && !message) {
            message = validationMessage.dateValidation;
        }
    }

    if (rules.alphaNumeric) {
        const pattern = /^[a-z0-9\d\s]+$/i;
        isValid = pattern.test(value) && isValid;
        if (!isValid && typeof validationMessage.alphaNumeric != undefined && !message) {
            message = validationMessage.alphaNumeric;
        }
    }

    if (rules.alpha) {
        const pattern = /^[a-z\s]+$/i;
        isValid = pattern.test(value) && isValid;
        if (!isValid && typeof validationMessage.alpha != undefined && !message) {
            message = validationMessage.alpha;
        }
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid;
        if (!isValid && typeof validationMessage.isNumeric != undefined && !message) {
            message = validationMessage.isNumeric;
        }
    }

    if (rules.minNumber) {
        console.log(value);
        isValid = value > 0 && isValid;
        if (!isValid && typeof validationMessage.minNumber != undefined && !message) {
            message = validationMessage.minNumber;
        }
    }
    if (rules.maxNumber) {
        isValid = value <= rules.maxNumber && isValid;
        if (!isValid && typeof validationMessage.maxNumber != undefined && !message) {
            message = validationMessage.maxNumber;
        }
    }



    return { validAction: !isValid, errorMessage: message };
};

export default checkValidity;