import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import logoImg from '../../assets/images/gravity.png'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormValidation from '../../components/UI/formValidationMessage/formValidationMessage.js';
import { Link, useHistory } from "react-router-dom";
import checkValidity from '../../common/appValidation';
import constantData from '../../common/appConstant.js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';
import axios from 'axios';
import { getOnlyAuthToken } from '../../common/appAuthTokenProvider';
import AppAlert from '../../common/appAlert';

const useStyles = makeStyles((theme) => ({
    loginContainer: {
        minWidth: 275,
    },
    logo: {
        width: "55%",
        height: "auto",
    },
    logoContainer: {
        textAlign: "center"
    }
}
));

function Login(props) {
    const classes = useStyles();
    const [loginFormState, setLoginFormState] = useState({
        loginForm: {
            username: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Username'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 3
                },
                validationMessage: {
                    required: "Username is required",
                    minLength: "Please enter valid data"
                },
                errorMessage: "",
                valid: false,
                touched: false
            },
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Password'
                },
                value: '',
                validation: {
                    required: true,
                    maxLength: 30
                },
                validationMessage: {
                    required: "Password is required",
                    others: "Please enter valid data",
                    maxLength: "Please enter valid data"
                },
                errorMessage: "",
                valid: false,
                touched: false
            },
        },
        formIsValid: false,
        loading: false
    });

    useEffect(() => {
        // const authData = reactLocalStorage.getObject('authData');
        // const authUserData = reactLocalStorage.getObject('authUserData');
        // if (authData !== null && typeof authData.access_token !== 'undefined') {
        //     if (parseInt(authUserData['wrkuserrole'].trim()) >= 5) {
        //         history.push("/app/mis-report");
        //     } else {
        //         history.push("/app/dashboard");
        //     }
        // }
        props.onAuthAction(false, {});
    }, []);


    let history = useHistory();


    const formElementsArray = [];
    for (let key in loginFormState.loginForm) {
        formElementsArray.push({
            id: key,
            config: loginFormState.loginForm[key]
        });
    }


    const loginHandler = async (event) => {
        event.preventDefault();
        if (checkIsFormValid()) {
            const formData = {};
            for (let formElementIdentifier in loginFormState.loginForm) {
                formData[formElementIdentifier] = loginFormState.loginForm[formElementIdentifier].value;
            }

            var details = {
                grant_type: "password",
                username: formData["username"],
                password: btoa(formData["password"])
            };

            var formBody = [];
            for (var property in details) {
                var encodedKey = encodeURIComponent(property);
                var encodedValue = encodeURIComponent(details[property]);
                formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
            props.onApiCall();

            axios.get(constantData.baseUrl + 'api/GetUserAuthentication', {
                params: {
                    "username": formData["username"],
                    "authCode": btoa(formData["password"])
                }
            }).then(function (response) {
                if (response.status === 200 && response.data.Result === "SUCCESS") {
                    reactLocalStorage.setObject('authLoginDetails', response.data);
                    if (response.data.loginattempts > 5) {
                        props.onApiCall();
                        props.onAlertAction(true, "Reset password required. Allowable attempts exceeded. Use Forgot password to Reset.");
                        setTimeout(() => {
                            history.push("/forget-password");
                        }, 2000);
                        return;
                    }
                    if (response.data.loginattempts == 0) {
                        fetch(constantData.baseUrl + "token", {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                            },
                            body: formBody
                        }).then(response => response.json()).then(result => {
                            if (typeof result.error_description == 'undefined') {
                                const now = new Date();
                                now.setSeconds(now.getSeconds() + result['expires_in']);
                                result['expiry_at'] = now.getTime();
                                reactLocalStorage.setObject('authData', result);
                                var updateUserLoginStatus = {
                                    username: formData["username"],
                                    loginstatus: "Y",
                                };

                                var formBodyLoginStatus = [];
                                for (var property in updateUserLoginStatus) {
                                    var encodedKey = encodeURIComponent(property);
                                    var encodedValue = encodeURIComponent(updateUserLoginStatus[property]);
                                    formBodyLoginStatus.push(encodedKey + "=" + encodedValue);
                                }
                                formBodyLoginStatus = formBodyLoginStatus.join("&");

                                fetch(constantData.baseUrl + 'api/UpdateUserLoginStatus?' + formBodyLoginStatus, {
                                    method: 'POST',
                                    headers: {
                                        'Authorization': getOnlyAuthToken()
                                    }
                                });

                                axios.get(constantData.baseUrl + 'api/GetUser', {
                                    params: {
                                        "userid": response.data.wrkuserid,
                                        "wrkstatus": 2
                                    },
                                    headers: {
                                        'Authorization': getOnlyAuthToken()
                                    }
                                }).then(function (responseUser) {
                                    props.onApiCall();
                                    if (responseUser.status == "200") {
                                        if (parseInt(responseUser.data.wrkstatus.trim()) === 2) {
                                            reactLocalStorage.setObject('authUserData', responseUser.data);
                                            props.onAuthAction(true, responseUser.data);
                                            props.onAlertAction(true, "Welcome " + responseUser.data.wrkuserfirstname + ". Login Success!!!");
                                            setTimeout(() => {
                                                if (response.data.logincheck != "" && response.data.logincheck.trim() === "F") {
                                                    history.push("/firstime-login");
                                                } else {
                                                    props.onAlertAction(true, "Unauthorized use of the DATAMARK Inc. applications is prohibited");
                                                    if (parseInt(responseUser.data['wrkuserrole'].trim()) >= 5 && parseInt(responseUser.data['wrkuserrole'].trim()) <= 7) {
                                                        history.push("/app/mis-report");
                                                    } else {
                                                        history.push("/app/dashboard");
                                                    }
                                                }
                                            }, 2000);
                                        } else {
                                            props.onAlertAction(true, responseUser.data.txtwrkstatus);
                                        }
                                    }
                                }).catch(function (error) {
                                    // handle error
                                    console.log(error);
                                });
                            } else {
                                props.onApiCall();
                                props.onAlertAction(true, "Invalid Username or Password");
                            }
                        }).catch(function (error) {
                            // handle error
                            console.log(error);
                        });
                    } else {
                        props.onApiCall();
                        props.onAlertAction(true, response.data.Result);
                    }
                } else {
                    props.onApiCall();
                    props.onAlertAction(true, response.data.Result);
                }
            }).catch(error => {
                props.onApiCall();
                console.error('Error:', error);
                props.onAlertAction(true, "Invalid Username or Password");
            });
        }
    };

    const checkIsFormValid = () => {
        const updatedOrderForm = {
            ...loginFormState.loginForm
        };
        let formIsValid = true;
        for (let formElementIdentifier in loginFormState.loginForm) {
            const validationResult = checkValidity(updatedOrderForm[formElementIdentifier].value, updatedOrderForm[formElementIdentifier].validation, updatedOrderForm[formElementIdentifier].validationMessage);
            updatedOrderForm[formElementIdentifier].valid = validationResult.validAction;
            updatedOrderForm[formElementIdentifier].errorMessage = validationResult.errorMessage;
            updatedOrderForm[formElementIdentifier].touched = true;
            formIsValid = !updatedOrderForm[formElementIdentifier].valid && formIsValid;
        }
        setLoginFormState({ loginForm: updatedOrderForm, formIsValid: formIsValid });
        return formIsValid;
    }


    const inputChangedHandler = (event, inputIdentifier) => {
        const updatedOrderForm = {
            ...loginFormState.loginForm
        };
        const updatedFormElement = {
            ...updatedOrderForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        const validationResult = checkValidity(updatedFormElement.value, updatedFormElement.validation, updatedFormElement.validationMessage);
        updatedFormElement.valid = validationResult.validAction;
        updatedFormElement.errorMessage = validationResult.errorMessage;
        updatedFormElement.touched = true;
        updatedOrderForm[inputIdentifier] = updatedFormElement;
        let formIsValid = false;
        for (let inputIdentifier in updatedOrderForm) {
            formIsValid = updatedOrderForm[inputIdentifier].valid && formIsValid;
        }
        setLoginFormState({ loginForm: updatedOrderForm, formIsValid: formIsValid });
    };


    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="#1A2960"
            height="100vh"
        >
            <AppAlert />
            <Container maxWidth="xs">
                <Card className={classes.loginContainer}>
                    <CardContent>
                        <div align='center'>
                            <Avatar variant="square" alt="GRAVITY" src={logoImg} className={classes.logo} />
                        </div>
                        <form noValidate autoComplete="off" onSubmit={loginHandler}>
                            {formElementsArray.map(formElement => (
                                <React.Fragment key={formElement.id}>
                                    <TextField
                                        required={formElement.config.validation.required}
                                        fullWidth
                                        style={{ marginTop: 30 }}
                                        id={formElement.id}
                                        onChange={(event) => inputChangedHandler(event, formElement.id)}
                                        label={formElement.config.elementConfig.placeholder}
                                        variant="outlined"
                                        error={formElement.config.valid}
                                        type={formElement.config.elementConfig.type}
                                    />
                                    <FormValidation errorMessage={formElement.config.errorMessage} />

                                </React.Fragment>
                            ))}
                            <div align="right" style={{ marginTop: 15 }}>
                                <p><Link to="/forget-password" className="aAsHyper">Forget Password?</Link></p>
                            </div>
                            <div align="center">
                                <Button variant="contained" disabled={props.lodScr} type="submit" color="secondary" style={{ marginTop: 20, width: "100%" }}>
                                    Login
                                </Button>
                            </div>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        </Box>
    );
}

const mapStateToProps = state => {
    return {
        lodScr: state.lodingRed.showLoading,
        authRed: state.authRed.isAuthValid
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onApiCall: () => dispatch({ type: actionTypes.SHOWLOADING }),
        onAuthAction: (isAuthValid, authUserData) => dispatch({ type: actionTypes.CHANGEAUTHSTATUS, authUserData: authUserData, isAuthValid: isAuthValid }),
        onAlertAction: (showAlert, alertMessage) => dispatch({ type: actionTypes.CHANGEALERTSTATUS, alertMessage: alertMessage, showAlert: showAlert })

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);

