import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import checkValidity from '../../common/appValidation';
import constantData from '../../common/appConstant.js';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';
import axios from 'axios';
import AppAlert from '../../common/appAlert';
import FormInputs from '../../components/UI/formInputs/formInputs.js';

const useStyles = makeStyles((theme) => ({
    forgetPasswordContainer: {
        minWidth: 275,
    }
}
));

function ForgetPassword(props) {

    useEffect(() => {
        props.onApiCall();
        props.onAlertAction(false, "");
        axios.get(constantData.baseUrl + 'api/GetMasterList?Mastercode=SecretQues').then(function (response) {
            props.onApiCall();
            if (response.status == "200") {
                createSecreatQuestion(response.data);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }, []);

    const classes = useStyles();
    const [forgetPasswordFormState, setforgetPasswordFormState] = useState({
        forgetPasswordForm: {
            username: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Username'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 3
                },
                validationMessage: {
                    required: "Username is required",
                    minLength: "Please enter valid data"
                },
                selectOption: [],
                errorMessage: "",
                valid: false,
                touched: false
            },
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'New Password'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 7,
                    password: true,
                    maxLength: 30
                },
                validationMessage: {
                    required: "Password is required",
                    minLength: "Minimum 7 characters in length",
                    others: "Please enter valid data",
                    password: "Password should have at least, 1 Capital Letter, 1 Number, 1 Special Character",
                    maxLength: "Please enter valid data"
                },
                selectOption: [],
                errorMessage: "",
                valid: false,
                touched: false
            },
            confirmPassword: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Confirm Password'
                },
                value: '',
                validation: {
                    required: true,
                    sameAs: "password",
                },
                validationMessage: {
                    required: "Confirm Password is required",
                    sameAs: "Confirm Password should be same as New Password",
                    others: "Please enter valid data",
                },
                selectOption: [],
                errorMessage: "",
                valid: false,
                touched: false
            },
            secretQuestion: {
                elementType: 'select-single',
                elementConfig: {
                    type: 'select',
                    placeholder: 'Secret Question'
                },
                value: '',
                validation: {
                    required: true,
                },
                validationMessage: {
                    required: "Secret Question is required",
                },
                selectOption: [],
                errorMessage: "",
                valid: false,
                touched: false
            },
            secretAnswer: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Secret Answer'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 3,
                    maxLength: 30
                },
                validationMessage: {
                    required: "Secret Answer is required",
                    minLength: "Please enter valid data",
                    maxLength: "Please enter valid data"
                },
                selectOption: [],
                errorMessage: "",
                valid: false,
                touched: false
            },
        },
        formIsValid: false,
        loading: false
    });

    const formElementsArray = [];
    for (let key in forgetPasswordFormState.forgetPasswordForm) {
        formElementsArray.push({
            id: key,
            config: forgetPasswordFormState.forgetPasswordForm[key]
        });
    }

    const createSecreatQuestion = (data) => {
        const updatedOrderForm = {
            ...forgetPasswordFormState.forgetPasswordForm
        };
        const createNewSelectArray = [];
        for (let key in data) {
            createNewSelectArray.push({
                id: data[key].mstindex.trim(),
                value: data[key].msttext
            });
        }
        updatedOrderForm['secretQuestion'].selectOption = createNewSelectArray;
        setforgetPasswordFormState({ forgetPasswordForm: updatedOrderForm, formIsValid: false, loading: false });
        console.log(updatedOrderForm['secretQuestion']);
    }

    let history = useHistory();

    const forgetPasswordHandler = (event) => {
        event.preventDefault();
        if (checkIsFormValid()) {
            props.onApiCall();
            const formData = {};
            for (let formElementIdentifier in forgetPasswordFormState.forgetPasswordForm) {
                formData[formElementIdentifier] = forgetPasswordFormState.forgetPasswordForm[formElementIdentifier].value;
            }

            var details = {
                username: formData["username"],
                newpassword: btoa(formData["password"]),
                SecretQuestion: formData["secretQuestion"],
                SecretAnswer: btoa(formData["secretAnswer"]),
            };

            var formBodyLoginStatus = [];
            for (var property in details) {
                var encodedKey = encodeURIComponent(property);
                var encodedValue = encodeURIComponent(details[property]);
                formBodyLoginStatus.push(encodedKey + "=" + encodedValue);
            }
            formBodyLoginStatus = formBodyLoginStatus.join("&");

            fetch(constantData.baseUrl + 'api/ResetUserAuthentication?' + formBodyLoginStatus, {
                method: 'POST',
            }).then(response => response.json()).then(result => {
                props.onApiCall();
                if (result === "FAIL") {
                    props.onAlertAction(true, "Invalid Secret Question and Answer");
                } else {
                    history.push('/');
                    props.onAlertAction(true, "Success!!! Password updated successfully");
                }
            });
        }
    };

    const checkIsFormValid = () => {
        const updatedOrderForm = {
            ...forgetPasswordFormState.forgetPasswordForm
        };
        let formIsValid = true;
        for (let formElementIdentifier in forgetPasswordFormState.forgetPasswordForm) {
            const validationResult = checkValidity(updatedOrderForm[formElementIdentifier].value, updatedOrderForm[formElementIdentifier].validation, updatedOrderForm[formElementIdentifier].validationMessage, updatedOrderForm);
            updatedOrderForm[formElementIdentifier].valid = validationResult.validAction;
            updatedOrderForm[formElementIdentifier].errorMessage = validationResult.errorMessage;
            updatedOrderForm[formElementIdentifier].touched = true;
            formIsValid = !updatedOrderForm[formElementIdentifier].valid && formIsValid;
        }
        setforgetPasswordFormState({ forgetPasswordForm: updatedOrderForm, formIsValid: formIsValid });
        return formIsValid;
    }


    const inputChangedHandler = (event, inputIdentifier) => {
        const updatedOrderForm = {
            ...forgetPasswordFormState.forgetPasswordForm
        };
        const updatedFormElement = {
            ...updatedOrderForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        const validationResult = checkValidity(updatedFormElement.value, updatedFormElement.validation, updatedFormElement.validationMessage, updatedOrderForm);
        updatedFormElement.valid = validationResult.validAction;
        updatedFormElement.errorMessage = validationResult.errorMessage;
        updatedFormElement.touched = true;
        updatedOrderForm[inputIdentifier] = updatedFormElement;
        let formIsValid = false;
        for (let inputIdentifier in updatedOrderForm) {
            formIsValid = updatedOrderForm[inputIdentifier].valid && formIsValid;
        }
        setforgetPasswordFormState({ forgetPasswordForm: updatedOrderForm, formIsValid: formIsValid });
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="#1A2960"
            height="100vh"
        >
            <AppAlert />
            <Container maxWidth="xs">
                <Card className={classes.forgetPasswordContainer}>
                    <CardContent>
                        <div align='center'>
                            <h3>Forget Password</h3>
                        </div>
                        <form noValidate autoComplete="off" onSubmit={forgetPasswordHandler}>
                            {formElementsArray.map(formElement => (
                                <FormInputs key={formElement.id}
                                    itemRequired={formElement.config.validation.required}
                                    itemId={formElement.id}
                                    changeHandaler={(event) => inputChangedHandler(event, formElement.id)}
                                    itemPlaceholder={formElement.config.elementConfig.placeholder}
                                    itemValid={formElement.config.valid}
                                    itemType={formElement.config.elementConfig.type}
                                    errorMessage={formElement.config.errorMessage}
                                    elementType={formElement.config.elementType}
                                    selectOptions={formElement.config.selectOption}
                                    inputData={formElement.config.value}
                                />
                            ))}
                            <div align="right" style={{ marginTop: 15 }}>
                                <p><Link to="/" className="aAsHyper">Back to Login</Link></p>
                            </div>
                            <div align="center">
                                <Button variant="contained" disabled={props.lodScr} type="submit" color="secondary" style={{ marginTop: 20, width: "100%" }}>
                                    Reset Password
                                </Button>
                            </div>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        </Box>
    );
}

const mapStateToProps = state => {
    return {
        lodScr: state.lodingRed.showLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onApiCall: () => dispatch({ type: actionTypes.SHOWLOADING }),
        onAlertAction: (showAlert, alertMessage) => dispatch({ type: actionTypes.CHANGEALERTSTATUS, alertMessage: alertMessage, showAlert: showAlert })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);

