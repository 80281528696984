import * as actionTypes from '../actions';

const initialState = {
    showLoading: false
}

const reducer = (state = initialState, action) => {
    if (action.type === actionTypes.SHOWLOADING) {
        return {
            showLoading: !state.showLoading
        }
    }
    return state;
}

export default reducer;