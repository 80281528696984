import * as actionTypes from '../actions';

const initialState = {
    isAuthValid: false,
    authUserData: {}
}

const reducer = (state = initialState, action) => {
    if (action.type === actionTypes.CHANGEAUTHSTATUS) {
        return {
            isAuthValid: action.isAuthValid,
            authUserData: action.authUserData
        }
    }
    return state;
}

export default reducer;