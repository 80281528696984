
const constantData = {
    //baseUrl: "https://gravityapi-dev.azurewebsites.net/", //Azure development environment
    //baseUrl: "http://localhost:3097/", //Development PC enviroment
    baseUrl: "https://gravityapi.datamark.net/", //Production environment
    deliveryInfo: "/cgi-bin/PKG621CL?IIWEBI=&IIFORM=03&IITMSD=Y&IICNT1=Y&IITRAK=",
    mainInfo: "/cgi-bin/PKG621CL?IIWEBI=&IIFORM=01&IITMSD=Y&IICNT1=Y&IITRAKS="
};

export default constantData;
