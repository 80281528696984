import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormValidation from '../formValidationMessage/formValidationMessage';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import MinusIcon from '@material-ui/icons/Remove';
import ListSubheader from '@material-ui/core/ListSubheader';
import { DatePicker, DateTimePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: 10,
        minWidth: "100%",
    },
    selectEmpty: {
        width: 100
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    hidden: {
        display: 'none'
    }
}));

const FormInputs = (props) => {

    useEffect(() => {

    }, []);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const classes = useStyles();

    switch (props.elementType) {
        case 'input': {
            return (
                <React.Fragment>
                    <TextField
                        required={props.itemRequired}
                        fullWidth
                        style={{ marginTop: 10 }}
                        id={props.itemId}
                        onChange={(event) => props.changeHandaler(event, props.itemId)}
                        label={props.itemPlaceholder}
                        variant="outlined"
                        disabled={props.inputStatus != null ? (props.inputStatus == "disabled" ? true : false) : false}
                        error={props.itemValid}
                        value={props.inputData}
                        type={props.itemType}
                        inputProps={{
                            min: 0
                        }}
                    />
                    <FormValidation errorMessage={props.errorMessage} />

                </React.Fragment>
            );
        };
        case 'textarea': {
            return (
                <React.Fragment>
                    <TextField
                        required={props.itemRequired}
                        fullWidth
                        style={{ marginTop: 10 }}
                        id={props.itemId}
                        onChange={(event) => props.changeHandaler(event, props.itemId)}
                        label={props.itemPlaceholder}
                        variant="outlined"
                        disabled={props.inputStatus != null ? (props.inputStatus == "disabled" ? true : false) : false}
                        error={props.itemValid}
                        value={props.inputData}
                        type={props.itemType}
                        multiline={true}
                    />
                    <FormValidation errorMessage={props.errorMessage} />

                </React.Fragment>
            );
        };
        case 'bold-textarea': {
            return(
                <React.Fragment>
                    <div
                        style={{ marginTop: 10, 
                            fontWeight: 'bold',
                            border: '1px solid',
                            borderRadius: 4,
                            padding: 18,
                        }}
                        id={props.itemId}
                        onChange={(event) => props.changeHandaler(event, props.itemId)}
                        type={props.itemType}
                    >VOC Note: {props.inputData}</div>

                </React.Fragment>
            )
            
        };
        case 'select-single': {
            return (
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id={props.itemId} required={props.itemRequired}>{props.itemPlaceholder}</InputLabel>
                    <Select
                        required={props.itemRequired}
                        labelId={props.itemId}
                        id={props.itemId}
                        onChange={(event) => props.changeHandaler(event, props.itemId)}
                        onSelectCapture={(event) => props.SelectHandaler(event, props.itemId)}
                        label={props.itemPlaceholder}
                        value={props.inputData}
                        disabled={props.inputStatus != null ? (props.inputStatus == "disabled" ? true : false) : false}
                        error={props.itemValid}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {props.selectOptions.map(options => (
                            (typeof options.displaylabel == 'undefined') ? <MenuItem key={options.id} value={options.id}>{options.value}</MenuItem> : <ListSubheader key={options.id} className="dblock">{options.value}</ListSubheader>

                        ))}
                    </Select>
                    <FormValidation errorMessage={props.errorMessage} />
                </FormControl>
            );
        }
        case 'select-multiple': {
            const HandleGetValue = (dropdown, value) => {
                const makearray = [
                    ...dropdown
                ];
                const result = makearray.filter(el => (el.id == value) ?? el);
                return typeof result[0] != 'undefined' ? result[0].value : "";
            }
            return (
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id={props.itemId} required={props.itemRequired}>{props.itemPlaceholder}</InputLabel>
                    <Select
                        multiple
                        required={props.itemRequired}
                        labelId={props.itemId}
                        id={props.itemId}
                        onChange={(event) => props.changeHandaler(event, props.itemId)}
                        label={props.itemPlaceholder}
                        value={props.inputData}
                        error={props.itemValid}
                        disabled={props.inputStatus != null ? (props.inputStatus == "disabled" ? true : false) : false}
                        input={<Input id={props.itemId} />}
                        renderValue={(selected) => (
                            <div className={classes.chips}>
                                {selected.map((value) => (
                                    <Chip key={value} label={HandleGetValue(props.selectOptions, value)} className={classes.chip} />
                                ))}
                            </div>
                        )}
                        MenuProps={MenuProps}
                    >
                        {props.selectOptions.map(options => (
                            (typeof options.displaylabel == 'undefined') ? <MenuItem key={options.id} value={options.id}><Checkbox checked={props.inputData.includes(options.id)} /><ListItemText primary={options.value} /></MenuItem> : <ListSubheader key={options.id} className="dblock">{options.value}</ListSubheader>
                        ))}
                    </Select>
                    <FormValidation errorMessage={props.errorMessage} />
                </FormControl>
            );
        }
        case 'date-picker': {
            return (
                <React.Fragment>
                    <DatePicker
                        autoOk
                        clearable
                        required={props.itemRequired}
                        variant="inline"
                        id={props.itemId}
                        format="MM/DD/yyyy"
                        inputVariant="outlined"
                        className="fullWidth"
                        onChange={date => props.changeHandaler(date, props.itemId)}
                        label={props.itemPlaceholder}
                        error={props.itemValid}
                        maxDate={new Date()}
                        disabled={props.inputStatus != null ? (props.inputStatus == "disabled" ? true : false) : false}
                        value={props.inputData}
                    />
                    <FormValidation errorMessage={props.errorMessage} />

                </React.Fragment>
            );
        };
        case 'date-time-picker': {
            return (
                <React.Fragment>
                    <DateTimePicker
                        autoOk
                        clearable
                        required={props.itemRequired}
                        variant="inline"
                        id={props.itemId}
                        className="fullWidth"
                        format="MM/DD/yyyy hh:mm A"
                        inputVariant="outlined"
                        onChange={(date) => props.changeHandaler(date, props.itemId)}
                        label={props.itemPlaceholder}
                        error={props.itemValid}
                        minDate={typeof props.minDate !== "undefined" && props.minDate !== "" ? props.minDate : "2019-01-01"}
                        maxDate={new Date()}
                        disabled={props.inputStatus != null ? (props.inputStatus == "disabled" ? true : false) : false}
                        value={props.inputData}
                    />
                    <FormValidation errorMessage={props.errorMessage} />

                </React.Fragment>
            );
        };
        case 'time-picker': {
            return (
                <React.Fragment>
                    <TextField
                        required={props.itemRequired}
                        fullWidth
                        style={{ marginTop: 10 }}
                        id={props.itemId}
                        onChange={(event) => props.changeHandaler(event, props.itemId)}
                        label={props.itemPlaceholder}
                        variant="outlined"
                        error={props.itemValid}
                        disabled={props.inputStatus != null ? (props.inputStatus == "disabled" ? true : false) : false}
                        value={props.inputData}
                        type={props.itemType}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <FormValidation errorMessage={props.errorMessage} />

                </React.Fragment>
            );
        };
        case 'date-picker-validation': {
            return (
                <React.Fragment>
                    <DatePicker
                        autoOk
                        clearable
                        required={props.itemRequired}
                        disableToolbar
                        //variant="inline"
                        fullWidth
                        style={{ marginTop: 10 }}
                        id={props.itemId}
                        format="MM/dd/yyyy"
                        inputVariant="outlined"
                        views={["year", "month", "date"]}
                        onChange={(event) => props.changeHandaler(event, props.itemId)}
                        label={props.itemPlaceholder}
                        variant="outlined"
                        error={props.itemValid}
                        maxDate={new Date()}
                        disabled={props.inputStatus != null ? (props.inputStatus == "disabled" ? true : false) : false}
                        value={props.inputData}
                    />
                    <FormValidation errorMessage={props.errorMessage} />

                </React.Fragment>
            );
        };
        case 'table-with-input': {
            const createOptionList = [];
            const dummyinput = [];
            const dummyinputValidation = [];

            for (let key in props.itemTableData) {
                dummyinput[key] = "";
                dummyinputValidation[key] = [];
                createOptionList.push({
                    id: key,
                    config: props.itemTableData[key]
                });
            }
            const HandleAddNewRow = () => {
                var d = new Date();
                const newId = (props.inputData.length + 1) + d.getTime();
                let addNewData = {};
                if (typeof props.customTableSelect !== "undefined") {
                    addNewData = { ...dummyinput, id: newId, validation: { ...dummyinputValidation }, selectOption: props.itemTableData[props.customTableSelect].selectOption };
                } else {
                    addNewData = { ...dummyinput, id: newId, validation: { ...dummyinputValidation } };
                }
                props.addNewRecord(props.itemId, addNewData);
            }
            const HandleRemoveRow = (data) => {
                props.removeRecord(props.itemId, data);
            }

            const HandleChangeEvent = (event, inputIdentifier, id) => {
                if (inputIdentifier === "effDate") {
                    props.tableInputHandaler(event.format('yyyy-MM-DD'), inputIdentifier, id);
                } else {
                    props.tableInputHandaler(event.target.value, inputIdentifier, id);
                }
            }


            return (
                <React.Fragment>
                    {props.inputData.map(valueElement => (
                        <Grid container spacing={3} direction="row"
                            justify="center"
                            className={props.inputStatus != null ? (props.inputStatus == "disabled" ? classes.hidden : "") : ""}
                            alignItems="center" key={valueElement.id}>
                            {createOptionList.map(formElement => (
                                <Grid item xs={12} sm key={valueElement.id + formElement.id}>
                                    <FormInputs
                                        itemRequired={formElement.config.validation.required}
                                        itemId={formElement.id + valueElement.id}
                                        changeHandaler={(event) => HandleChangeEvent(event, formElement.id, valueElement.id)}
                                        itemPlaceholder={formElement.config.elementConfig.placeholder}
                                        itemValid={typeof valueElement['validation'] !== "undefined" && typeof valueElement['validation'][formElement.id] !== "undefined" ? valueElement['validation'][formElement.id]['valid'] : formElement.config.elementConfig.valid}
                                        itemType={formElement.config.elementConfig.type}
                                        errorMessage={typeof valueElement['validation'] !== "undefined" && typeof valueElement['validation'][formElement.id] !== "undefined" ? valueElement['validation'][formElement.id]['errorMessage'] : formElement.config.errorMessage}
                                        elementType={formElement.config.elementType}
                                        selectOptions={formElement.config.selectOption}
                                        inputData={valueElement[formElement.id]}
                                    />
                                </Grid>
                            ))}
                            <Grid item xs={12} sm={2}>
                                <Fab size="small" color="primary" aria-label="add" style={{ marginRight: 10 }} onClick={HandleAddNewRow}>
                                    <AddIcon />
                                </Fab>
                                {props.inputData.length > 1 ? <Fab size="small" color="secondary" aria-label="add" onClick={() => HandleRemoveRow(valueElement.id)}>
                                    <MinusIcon />
                                </Fab> : ""}
                            </Grid>
                        </Grid>
                    ))
                    }
                </React.Fragment>
            );
        };
    }



}

export default FormInputs;