import React, { useEffect, useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import { connect } from 'react-redux';

const AppAlert = (props) => {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(props.alrtRed.showAlert);
    }, [props.alrtRed]);

    const handleClick = () => {
        setOpen(false);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    if (props.alrtRed.showAlert) {
        return (
            <Snackbar open={open} message={props.alrtRed.alertMessage} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={2000} onClose={handleClose} onClick={handleClick} />
        );
    } else {
        return (
            <div></div>
        );
    }
}

const mapStateToProps = state => {
    return {
        alrtRed: state.alrtRed,
    }
}

export default connect(mapStateToProps)(AppAlert);